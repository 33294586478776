import React from 'react';
import { TypographyComponent } from '../styles/styles';
import { Stack } from '@mui/material';

type TitleTypographyProps = {
  titleText: string;
  secondaryText?: string;
  onClick?: () => void;
  sx?: any;
};

const TitleTypography: React.FC<TitleTypographyProps> = ({ titleText, secondaryText, onClick, sx }) => {
  return (
    <Stack onClick={onClick}>
      <TypographyComponent sx={{ ...sx }}>{titleText}</TypographyComponent>
      <TypographyComponent sx={{ ...sx }}>{secondaryText}</TypographyComponent>
    </Stack>
  );
};

export default TitleTypography;
