import { HttpStatusCode, isAxiosError } from 'axios';

export const handleAxiosError = (error: unknown, deviceId: string | null): void => {
  if (!error || !isAxiosError(error) || !deviceId) {
    return;
  }

  const status = error.response?.status;

  if (!status) {
    return;
  }

  if (status === HttpStatusCode.Unauthorized) {
    localStorage.clear();
    window.location.href = `/login?deviceId=${deviceId}`;

    return;
  }

  // if (status === HttpStatusCode.Forbidden) {
  //   window.location.href = PagePath.FORBIDDEN;
  //
  //   return;
  // }
};
