import React from 'react';
import { RenameNumberTypographyComponent } from '../styles/styles';

type RenameNumberTypographyProps = {
  onClick: () => void;
  text: string;
};

const RenameNumberTypography: React.FC<RenameNumberTypographyProps> = ({ onClick, text }) => {
  return <RenameNumberTypographyComponent onClick={onClick}>{text}</RenameNumberTypographyComponent>;
};

export default RenameNumberTypography;
