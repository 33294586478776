import React, { useEffect } from 'react';
import avatar from '../shared/assets/images/avatar.svg';
import coin from '../shared/assets/images/coin.svg';
import YellowButton from '../shared/ui-kit/button/yellow-button';
import { useNavigate } from 'react-router-dom';
import TitleTypography from '../shared/ui-kit/typography/title-typography';
import InactiveTypography from '../shared/ui-kit/typography/inactive-typography';
import {
  AccountContainer,
  AccountInfoContainer,
  AccountNameContainer,
  ButtonContainer,
  CoinTypographyContainer,
  NameTypographyComponent,
  SpaceBetweenContainer,
} from '../shared/ui-kit/styles/styles';
import axios from 'axios';
import { useRootStore } from '../shared/hooks/use-root-store';
import { observer } from 'mobx-react';
import { handleAxiosError } from '../shared/lib/handle-axios-error';

const AccountPage = observer(() => {
  const navigate = useNavigate();
  const { userStore } = useRootStore();
  const params = new URLSearchParams(location.search);
  const deviceId = params.get('deviceId');
  const token = localStorage.getItem('token')?.replace(/"/g, '');
  const userId = localStorage.getItem('user_id');

  useEffect(() => {
    if (!token || !userId) {
      navigate(`/login?deviceId=${deviceId}`);
    }

    if (!deviceId) {
      navigate('/scan-qr');

      return;
    }

    axios
      .get(`/api/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        userStore.setUserData(response.data);
      })
      .catch((e) => handleAxiosError(e, deviceId));
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    navigate(`/login?deviceId=${deviceId}`);
  };

  return (
    <>
      <AccountContainer>
        <AccountInfoContainer>
          <SpaceBetweenContainer>
            <AccountNameContainer>
              <img src={avatar} alt={''} />
              <NameTypographyComponent>{userStore.userData?.phone_number}</NameTypographyComponent>
            </AccountNameContainer>
          </SpaceBetweenContainer>
          <CoinTypographyContainer>
            У вас {userStore.userData?.bonus_balance ? Math.round(userStore.userData?.bonus_balance / 100) : 0}{' '}
            <img src={coin} alt={'Coin'} /> баллов
          </CoinTypographyContainer>
        </AccountInfoContainer>
        <YellowButton
          sx={{ position: 'absolute', bottom: '-70px' }}
          text={'Потратить баллы'}
          onClick={() => {
            navigate(`/?deviceId=${deviceId}`);
          }}
        />
      </AccountContainer>
      <ButtonContainer>
        <TitleTypography
          onClick={() => navigate(`/about-coins?deviceId=${deviceId}`)}
          titleText={'Подробно о баллах'}
        />
        <TitleTypography
          onClick={() => navigate(`/contract-offer?deviceId=${deviceId}`)}
          titleText={'Договор оферты'}
        />
        <InactiveTypography onClick={handleLogout} text={'Выйти'} />
      </ButtonContainer>
    </>
  );
});

export default AccountPage;
