import React, { ReactNode } from 'react';
import Header from '../ui-kit/header/header';
import BackgroundCircle from '../ui-kit/background/background-circle';
import { LayoutComponent } from '../ui-kit/styles/styles';

type LayoutProps = {
  children: ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <LayoutComponent>
      <BackgroundCircle isTopPosition={true} />
      <BackgroundCircle isTopPosition={false} />
      <Header />
      {children}
    </LayoutComponent>
  );
};

export default Layout;
