import React from 'react';
import { BottomCircleComponent, TopCircleComponent } from '../styles/styles';

type BackgroundCircleProps = {
  isTopPosition: boolean;
};

const BackgroundCircle: React.FC<BackgroundCircleProps> = ({ isTopPosition }) => {
  return isTopPosition ? <TopCircleComponent /> : <BottomCircleComponent />;
};

export default BackgroundCircle;
