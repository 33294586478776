import React from 'react';
import { SliderContentComponent } from '../styles/styles';

type SliderContentProps = {
  text: string;
};

const SliderContent: React.FC<SliderContentProps> = ({ text }) => {
  return <SliderContentComponent>{text}</SliderContentComponent>;
};

export default SliderContent;
