import React, { useEffect, useRef, useState } from 'react';
import TitleTypography from '../shared/ui-kit/typography/title-typography';
import {
  BlackButtonComponent,
  CodeInputContainer,
  LoginContainer,
  TimerTypographyComponent,
} from '../shared/ui-kit/styles/styles';
import { PatternFormat } from 'react-number-format';
import YellowButton from '../shared/ui-kit/button/yellow-button';
import SecondaryTypography from '../shared/ui-kit/typography/secondary-typography';
import RenameNumberTypography from '../shared/ui-kit/typography/rename-number-typography';
import { Box, CircularProgress, Grid, Stack } from '@mui/material';
import axios, { HttpStatusCode } from 'axios';
import Countdown, { zeroPad } from 'react-countdown';
import { useNavigate } from 'react-router-dom';
import theme from '../theme';
import { useDebounce } from '../shared/hooks/useDebaunce';
import { handleAxiosError } from '../shared/lib/handle-axios-error';

const LoginPage = () => {
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [isIncorrectCode, setIsIncorrectCode] = useState<boolean>(false);
  const [code, setCode] = useState<string[]>(['', '', '', '']);
  const [timerDate, setTimerDate] = useState(Date.now() + 60000);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [isRequestedForCode, setIsRequestedForCode] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const params = new URLSearchParams(location.search);
  const deviceId = params.get('deviceId');
  const userId = localStorage.getItem('user_id');
  const token = localStorage.getItem('token')?.replace(/"/g, '');

  useEffect(() => {
    if (userId && token) {
      axios
        .get(`/api/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res);
          navigate(`/?deviceId=${deviceId}`);
        })
        .catch((e) => handleAxiosError(e, deviceId));
    }
  }, []);

  const handleChange = (index: number, value: string) => {
    if (value.length > 1) {
      value = value.slice(0, 1);
    }

    const newCode = [...code];

    newCode[index] = value;
    setCode(newCode);

    if (value.length === 1 && index < code.length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]?.focus();
    }

    if (value === '' && index > 0 && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleLogin = async () => {
    setIsLoading(true);

    try {
      await axios
        .post(
          '/api/users/verification-code',
          { phone_number: phoneNumber?.replace(/[\s()-]+/g, '') },
          { withCredentials: true },
        )
        .then(() => {
          setIsLoading(false);
          setIsClicked(true);
        })
        .catch((error) => {
          if (error.response.status === HttpStatusCode.BadRequest) {
            setIsIncorrectCode(true);
          }
        });
    } catch (error) {
      setIsIncorrectCode(true);
    }
  };

  const debouncedSendCode = useDebounce((code: string) => {
    try {
      axios
        .post(
          '/api/users/auth',
          {
            code: code,
            phone_number: phoneNumber?.replace(/[\s()-]+/g, ''),
          },
          { withCredentials: true },
        )
        .then((response) => {
          setIsIncorrectCode(false);
          localStorage.setItem('user_id', JSON.stringify(response.data.user_id));
          localStorage.setItem('token', JSON.stringify(response.data.access_token));
          navigate(`/?deviceId=${deviceId}`);
        })
        .catch((error) => {
          if (error.response.status === HttpStatusCode.Unauthorized) {
            setIsIncorrectCode(true);
          }
        });
    } catch (error) {
      setIsIncorrectCode(true);
    }
  }, 1000);

  if (code.map((digit) => digit).join('').length === 4) {
    debouncedSendCode(code.map((digit) => digit).join(''));
  }

  return (
    <LoginContainer>
      {isClicked ? (
        <>
          <TitleTypography titleText={'Мы позвоним вам'} secondaryText={'по указанному телефону'} />
          {isIncorrectCode && <TitleTypography sx={{ color: theme.custom.coin.color }} titleText={'Неверный код'} />}
          <SecondaryTypography titleText={'Введите последние'} secondaryText={`4 цифры номера телефона`} />
          <Grid container spacing={2} alignItems='center'>
            {code.map((digit, index) => (
              <Grid item xs={3} key={index}>
                <CodeInputContainer
                  type={'number'}
                  placeholder={(index + 1).toString()}
                  inputRef={(el) => (inputRefs.current[index] = el)}
                  size='small'
                  value={digit}
                  onChange={(e) => {
                    handleChange(index, e.target.value);
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'center',
                      fontSize: '1.5rem',
                      color: 'white',
                      width: '37px',
                      height: '62px',
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
          <Stack gap={'35px'}>
            <BlackButtonComponent
              disabled={isDisabled}
              onClick={() => {
                setIsRequestedForCode(false);
                setTimerDate(Date.now() + 60000);
                handleLogin();
              }}
            >
              <Countdown
                zeroPadTime={1}
                onComplete={() => {
                  setIsDisabled(false);
                  setIsRequestedForCode(true);
                }}
                date={timerDate}
                renderer={({ minutes, seconds }) => (
                  <Stack alignItems={'center'} flexDirection={'row'}>
                    <TimerTypographyComponent>
                      {isRequestedForCode
                        ? 'Получить код повторно'
                        : ` Новый код через - ${zeroPad(minutes)}:${zeroPad(seconds)}`}
                    </TimerTypographyComponent>
                  </Stack>
                )}
              />
            </BlackButtonComponent>
            <RenameNumberTypography text={'Изменить номер телефона'} onClick={() => setIsClicked(false)} />
          </Stack>
        </>
      ) : (
        <>
          <TitleTypography titleText={'Пожалуйста авторизуйтесь, чтобы запустить массажное кресло'} />
          <>
            <PatternFormat
              className='pattern-format'
              format='+7 (###)-###-##-##'
              onChange={(event) => setPhoneNumber(event.target.value)}
              allowEmptyFormatting
              mask='_'
              data-cy='phone'
            />
          </>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <YellowButton
              isDisabled={phoneNumber ? phoneNumber?.replace(/[\s_()-]+/g, '').length < 12 : true}
              onClick={async () => {
                await handleLogin();
              }}
              text={'Войти'}
            />
          )}
        </>
      )}
      <Box sx={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
        Техническая поддержка:
        <a style={{ color: 'white' }} href={'tel:89128184182'}>
          +8 912 818 41 82
        </a>
      </Box>
    </LoginContainer>
  );
};

export default LoginPage;
