import React from 'react';

const CheckMarkComponent = () => {
  return (
    <svg width='148' height='97' viewBox='0 0 148 97' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M145.518 3.0489L56.4444 92.5019L3 38.8301'
        stroke='url(#paint0_linear_20_374)'
        stroke-width='6.06461'
        stroke-miterlimit='10'
      />
      <defs>
        <linearGradient
          id='paint0_linear_20_374'
          x1='74.2592'
          y1='266.879'
          x2='74.2591'
          y2='-170.196'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.321' stop-color='#F8EDB0' />
          <stop offset='0.587389' stop-color='#FFD700' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CheckMarkComponent;
