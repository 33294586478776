import React from 'react';
import { SecondaryTypographyComponent } from '../styles/styles';
import { Stack } from '@mui/material';

type SecondaryTypographyProps = {
  titleText: string;
  secondaryText: string;
};

const SecondaryTypography: React.FC<SecondaryTypographyProps> = ({ titleText, secondaryText }) => {
  return (
    <Stack>
      <SecondaryTypographyComponent>{titleText}</SecondaryTypographyComponent>
      <SecondaryTypographyComponent>{secondaryText}</SecondaryTypographyComponent>
    </Stack>
  );
};

export default SecondaryTypography;
