import React from 'react';
import CircleFigure from '../shared/ui-kit/figure/circle-figure';
import PaymentSecondaryTypography from '../shared/ui-kit/typography/payment-secondary-typography';
import { PaymentAcceptedContainer } from '../shared/ui-kit/styles/styles';

const PaymentAcceptedPage = () => {
  const params = new URLSearchParams(location.search);
  const deviceId = params.get('deviceId');

  setTimeout(() => {
    window.location.href = `/?deviceId=${deviceId}`;
  }, 5000);

  return (
    <PaymentAcceptedContainer>
      <CircleFigure />
      <PaymentSecondaryTypography text={'Наслаждайтесь заботой о Вашем теле!'} />
    </PaymentAcceptedContainer>
  );
};

export default PaymentAcceptedPage;
