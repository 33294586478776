import React from 'react';
import { SliderTitleComponent } from '../styles/styles';

type SliderTitleProps = {
  text: string;
};

const SliderTitle: React.FC<SliderTitleProps> = ({ text }) => {
  return <SliderTitleComponent>{text}</SliderTitleComponent>;
};

export default SliderTitle;
