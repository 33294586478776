import React from 'react';
import logo from '../../assets/images/logo.svg';

const LogoTypography = () => {
  return (
    <>
      <img src={logo} width={'100%'} alt='Олейников' />
    </>
  );
};

export default LogoTypography;
