import React, { ReactNode, useEffect } from 'react';
import axios from 'axios';

interface AppProps {
  children: ReactNode;
}

const App: React.FC<AppProps> = ({ children }) => {
  const params = new URLSearchParams(location.search);
  const deviceId = params.get('deviceId');
  const excludedPages = ['/scan-qr', '/payment-accepted', '/payment-denied', '/login', '/busy', '/contract-offer'];

  useEffect(() => {
    if (excludedPages.includes(window.location.pathname)) {
      return;
    }

    if (!deviceId) {
      window.location.href = '/scan-qr';

      return;
    }

    axios
      .get(`/api/device/${deviceId}`)
      .then((res) => {
        if (res.data.is_busy) {
          window.location.href = `/busy?deviceId=${deviceId}`;

          return;
        }
      })
      .catch(() => {
        window.location.href = '/scan-qr';

        return;
      });
  }, []);

  return <div>{children}</div>;
};

export default App;
