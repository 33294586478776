import React from 'react';
import AuthorizedHeader from './authorized-header';
import { UnauthorizedHeaderContainer } from '../styles/styles';
import LogoTypography from '../typography/logo-typography';

const Header = () => {
  const isAuthorized = localStorage.getItem('token');

  return (
    <>
      {isAuthorized ? (
        <AuthorizedHeader />
      ) : (
        <UnauthorizedHeaderContainer>
          <LogoTypography />
        </UnauthorizedHeaderContainer>
      )}
    </>
  );
};

export default Header;
