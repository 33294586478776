import { Box, Button, Stack, styled, TextField, Typography } from '@mui/material';
import theme from '../../../theme';

export const TopCircleComponent = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '160px',
  height: '160px',
  boxShadow: `0px 4px 30px 0px ${theme.custom.main.color}`,
  borderBottomRightRadius: '100%',
});

export const BottomCircleComponent = styled(Box)({
  position: 'fixed',
  right: 0,
  bottom: 0,
  width: '160px',
  height: '160px',
  boxShadow: `0px 4px 30px 0px ${theme.custom.main.color}`,
  borderTopLeftRadius: '100%',
});

export const LayoutComponent = styled(Stack)({
  padding: '0 35px 24px 35px',
  display: 'flex',
  alignItems: 'center',
  height: '100vh',
});

export const YellowButtonComponent = styled(Button)({
  marginBottom: '10px',
  color: theme.custom.main.background,
  fontSize: '21px',
  fontWeight: '700',
  fontFamily: 'Bellota Text',
  borderRadius: '20px',
  width: '322px',
  height: '79px',
  background: 'linear-gradient(to top, #F8EDB0, #FFD700)',
});

export const BlackButtonComponent = styled(Button)({
  color: theme.custom.text.white,
  background: theme.custom.main.background,
  fontSize: '21px',
  fontWeight: 700,
  fontFamily: 'Bellota Text',
  borderRadius: '20px',
  width: '342px',
  height: '79px',
  boxShadow: `0 4px 4px ${theme.custom.main.yellowBlur}`,
  '&:hover': {
    backgroundColor: theme.custom.main.background,
  },
});

export const BlackMassageButtonComponent = styled(Button)({
  color: theme.custom.text.white,
  position: 'relative',
  fontSize: '21px',
  fontWeight: '300',
  fontFamily: 'Bellota Text',
  borderRadius: '20px',
  width: '342px',
  height: '79px',
  boxShadow: `0 4px 4px ${theme.custom.main.color}`,
  textTransform: 'none',
});

export const RenameNumberTypographyComponent = styled(Typography)({
  color: theme.custom.text.gray,
  fontWeight: 300,
  fontSize: '16px',
  lineHeight: '17px',
  textAlign: 'center',
  fontFamily: 'Bellota Text',
  textDecoration: 'underline',
});
export const TypographyComponent = styled(Typography)({
  color: theme.custom.text.white,
  fontWeight: 700,
  fontSize: '26px',
  lineHeight: '28px',
  textAlign: 'center',
  width: '348px',
  fontFamily: 'Bellota Text',
});

export const SecondaryTypographyComponent = styled(Typography)({
  color: theme.custom.text.white,
  fontWeight: 300,
  fontSize: '20px',
  lineHeight: '22p',
  textAlign: 'center',
  fontFamily: 'Bellota Text',
});

export const CodeInputComponent = styled(TextField)({
  color: theme.custom.text.white,
  fontWeight: 300,
  fontSize: '20px',
  lineHeight: '22p',
  textAlign: 'center',
  fontFamily: 'Bellota Text',
  background: 'linear-gradient(to top, #F8EDB0, #FFD700)',
});

export const LoginContainer = styled(Stack)({
  zIndex: 999,
  marginTop: '66px',
  display: 'flex',
  alignItems: 'center',
  gap: '45px',
});

export const CoinTypographyComponent = styled(Typography)({
  fontFamily: 'Bellota Text',
  fontWeight: 300,
  fontSize: '19px',
  color: theme.custom.main.color,
  display: 'flex',
  alignItems: 'center',
  gap: '3px',
  position: 'absolute',
  right: '5px',
  top: '6px',
});

export const CodeInputContainer = styled(TextField)({
  boxShadow: '0 4px 4px #FFD700',
  borderRadius: '20px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
});

export const MassageContainer = styled(Stack)({
  marginTop: '53px',
  gap: '45px',
  alignItems: 'center',
  zIndex: 999,
});

export const HeaderContainer = styled(Stack)({
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  marginTop: '74px',
  zIndex: 999,
  width: '100%',
  position: 'relative',
});

export const UnauthorizedHeaderContainer = styled(Stack)({
  display: 'flex',
  alignItems: 'center',
  marginTop: '74px',
  zIndex: 999,
});

export const TimerTypographyComponent = styled(Typography)({
  textTransform: 'none',
  color: theme.custom.text.disable,
  fontWeight: 300,
  fontSize: '21px',
  fontFamily: 'Bellota Text',
});

export const SliderContentComponent = styled(Typography)({
  fontSize: '17px',
  fontWeight: 400,
  lineHeight: '19px',
  fontFamily: 'Bellota Text',
  color: theme.custom.main.background,
  marginTop: '15px',
});

export const SliderTitleComponent = styled(Typography)({
  fontSize: '22px',
  fontWeight: 700,
  lineHeight: '24px',
  fontFamily: 'Bellota Text',
  color: theme.custom.main.background,
  textTransform: 'uppercase',
});

export const SliderContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '50px',
});
export const SliderCarousel = styled(Box)({
  marginTop: '60px',
  width: '358px',
  height: '212px',
  borderRadius: '18px',
  textAlign: 'center',
  background: 'linear-gradient(to top, #F8EDB0, #FFD700)',
  padding: '20px',
});
export const InActiveTypographyComponent = styled(Typography)({
  textAlign: 'center',
  fontWeight: 700,
  fontSize: '26px',
  fontFamily: 'Bellota Text',
  color: theme.custom.text.inactive,
});

export const AccountContainer = styled(Stack)({
  marginTop: '65px',
  width: '390px',
  position: 'relative',
  alignItems: 'center',
});

export const CoinTypographyContainer = styled(Typography)({
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  fontSize: '26px',
  fontWeight: '700',
  lineHeight: 1,
  color: theme.custom.main.background,
  fontFamily: 'Bellota Text',
});

export const NameTypographyComponent = styled(Typography)({
  lineHeight: 1,
  fontWeight: 700,
  fontSize: '23px',
  fontFamily: 'Bellota Text',
  color: theme.custom.main.background,
});

export const AccountInfoContainer = styled(Stack)({
  backgroundColor: theme.custom.text.white,
  padding: '15px',
  borderRadius: '20px',
  gap: '15px',
  height: '141px',
  width: '100%',
});

export const SpaceBetweenContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
});

export const AccountNameContainer = styled(Box)({
  display: 'flex',
  gap: '15px',
  alignItems: 'center',
});

export const CircleFigureComponent = styled(Stack)({
  gap: '60px',
  alignItems: 'center',
  whiteSpace: 'pre-line',
  justifyContent: 'center',
  width: '350px',
  height: '350px',
  border: '4px solid #FFD700',
  borderRadius: '50%',
  color: theme.custom.text.white,
});

export const PaymentAcceptTypographyComponent = styled(Typography)({
  fontWeight: 300,
  width: '200px',
  fontSize: '26px',
  lineHeight: '28px',
  fontFamily: 'Bellota Text',
  textAlign: 'center',
  color: theme.custom.text.white,
});

export const PaymentAcceptedContainer = styled(Stack)({
  marginTop: '65px',
  gap: '45px',
  alignItems: 'center',
});

export const PaymentSecondaryTypographyComponent = styled(Typography)({
  fontWeight: 700,
  fontSize: '26px',
  lineHeight: '28px',
  fontFamily: 'Bellota Text',
  textAlign: 'center',
  color: theme.custom.text.white,
});

export const PaymentDeniedContainer = styled(Stack)({
  marginTop: '65px',
  gap: '45px',
  alignItems: 'center',
});

export const SupportNumberComponent = styled(Typography)({
  color: theme.custom.text.yellow,
  fontWeight: 400,
  fontSize: '30px',
  lineHeight: '3px',
  fontFamily: 'Bellota Text',
  textDecoration: 'underline',
});
export const InfoTypographyComponent = styled(Typography)({
  fontFamily: 'Bellota Text',
  fontWeight: 700,
  fontSize: '20px',
  color: theme.custom.text.white,
  lineHeight: '1.5rem',
});

export const InfoContainer = styled(Stack)({
  gap: '16px',
  marginTop: '20px',
  zIndex: 999,
});

export const LayoutDocsContainer = styled(Stack)({
  padding: '16px',
  marginTop: '24px',
});

export const ButtonContainer = styled(Stack)({
  gap: '35px',
  marginTop: '95px',
  zIndex: 999,
});

export const PaymentMethodContainer = styled(Stack)({
  borderRadius: '20px',
  boxShadow: `0px 4px 15px 0px ${theme.custom.main.color}`,
  padding: '35px 15px',
  gap: '30px',
});

export const PaymentMethodTypographyComponent = styled(Typography)({
  color: theme.custom.text.yellow,
  fontWeight: 700,
  fontSize: '26px',
  fontFamily: 'Bellota Text',
  lineHeight: '1',
  textAlign: 'center',
});

export const BlackPaymentButtonComponent = styled(Box)({
  color: theme.custom.text.white,
  display: 'flex',
  fontSize: '22px',
  fontWeight: '700',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Bellota Text',
  borderRadius: '20px',
  width: '100%',
  height: '79px',
  boxShadow: `0 4px 4px ${theme.custom.main.color}`,
  textTransform: 'none',
});
