import React from 'react';
import { PaymentDeniedContainer } from '../shared/ui-kit/styles/styles';
import PaymentSecondaryTypography from '../shared/ui-kit/typography/payment-secondary-typography';
import SupportNumberTypography from '../shared/ui-kit/typography/support-number-typography';
import YellowButton from '../shared/ui-kit/button/yellow-button';
import { useNavigate } from 'react-router-dom';

const PaymentDeniedPage = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const deviceId = params.get('deviceId');

  return (
    <PaymentDeniedContainer>
      <PaymentSecondaryTypography text={'Извините оплата не прошла'} />
      <PaymentSecondaryTypography
        text={'Попробуйте оплатить снова или обратитесь в службу поддержки по номеру телефона:'}
      />
      <SupportNumberTypography />
      <YellowButton text={'повторить попытку'} onClick={() => navigate(`/?deviceId=${deviceId}`)} />
    </PaymentDeniedContainer>
  );
};

export default PaymentDeniedPage;
