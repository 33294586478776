import React from 'react';
import { BlackPaymentButtonComponent } from '../styles/styles';

type BlackPaymentButtonProps = {
  text: string;
  onClick?: () => void;
};

const BlackPaymentButton: React.FC<BlackPaymentButtonProps> = ({ text, onClick }) => {
  return <BlackPaymentButtonComponent onClick={onClick}>{text}</BlackPaymentButtonComponent>;
};

export default BlackPaymentButton;
