import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SliderCarousel, SliderContainer } from '../shared/ui-kit/styles/styles';
import SliderTitle from '../shared/ui-kit/slider/slider-title';
import SliderContent from '../shared/ui-kit/slider/slider-content';
import Slider from 'react-slick';
import { Box, CircularProgress } from '@mui/material';
import BlackButton from '../shared/ui-kit/button/black-button';
import ArrowBackComponent from '../shared/assets/icon-component/arrow-back-component';
import { Program } from '../types/programs.type';
import axios from 'axios';
import { handleAxiosError } from '../shared/lib/handle-axios-error';

const HowToChangePage = () => {
  const [programs, setPrograms] = useState<Program[]>([]);
  const token = localStorage.getItem('token')?.replace(/"/g, '');
  const params = new URLSearchParams(location.search);
  const deviceId = params.get('deviceId');
  const fetchPrograms = async () => {
    try {
      const response = await axios.get(`/api/device/${deviceId}/programs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });

      setPrograms(response.data);
    } catch (error) {
      handleAxiosError(error, deviceId);
    }
  };

  useEffect(() => {
    fetchPrograms();
  }, []);

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      {programs.length ? (
        <SliderContainer>
          <SliderCarousel>
            <Slider {...sliderSettings}>
              {programs.map((program) => (
                <Box key={program.id}>
                  <SliderTitle text={program.name} />
                  <SliderContent text={program.description} />
                </Box>
              ))}
            </Slider>
          </SliderCarousel>
          <BlackButton Icon={<ArrowBackComponent />} text={'назад'} textTransform={'uppercase'} />
        </SliderContainer>
      ) : (
        <CircularProgress sx={{ marginTop: '100px' }} />
      )}
    </>
  );
};

export default HowToChangePage;
