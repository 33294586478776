import React from 'react';
import { PaymentAcceptTypographyComponent } from '../styles/styles';

type PaymentAcceptTypographyProps = {
  text: string;
};

const PaymentAcceptTypography: React.FC<PaymentAcceptTypographyProps> = ({ text }) => {
  return <PaymentAcceptTypographyComponent>{text}</PaymentAcceptTypographyComponent>;
};

export default PaymentAcceptTypography;
