import { User } from '../types/user.type';
import { action, makeAutoObservable, observable } from 'mobx';
import { IUserStore } from '../interfaces/IUserStore';

export class UserStore implements IUserStore {
  @observable userData: User | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setUserData(userData: User | null) {
    this.userData = userData;
  }
}
