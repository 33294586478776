import React from 'react';
import { Stack } from '@mui/material';
import { InfoContainer, InfoTypographyComponent } from '../shared/ui-kit/styles/styles';

const AboutCoins = () => {
  return (
    <InfoContainer>
      <Stack>
        <InfoTypographyComponent>
          Все деньги внесенные за массаж возвращаются баллами на ваш бонусный счет. Скидки и баллы не сумируются.
        </InfoTypographyComponent>
        <InfoTypographyComponent>Баллы нельзя использовать на оплату массажа.</InfoTypographyComponent>
      </Stack>
      <InfoTypographyComponent>
        Накопленные баллы Вы сможете использовать для оплаты ваших последующих покупок по курсу 1 балл = 1 рубль.
      </InfoTypographyComponent>
      <Stack>
        <InfoTypographyComponent>
          Оплатить баллами вы можете до 50% стоимости любого товара стоимостью от 1600 рублей.
        </InfoTypographyComponent>
      </Stack>
      <Stack>
        <InfoTypographyComponent>Скидки и баллы не суммируются.</InfoTypographyComponent>
      </Stack>
    </InfoContainer>
  );
};

export default AboutCoins;
