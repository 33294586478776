import React from 'react';
import { BlackMassageButtonComponent } from '../styles/styles';
import CoinTypography from '../typography/coin-typography';

type BlackMassageButtonProps = {
  text: string | number;
  bonusAmount?: number;
  onClick?: () => void;
};

const BlackMassageButton: React.FC<BlackMassageButtonProps> = ({ text, bonusAmount, onClick }) => {
  return (
    <BlackMassageButtonComponent onClick={onClick}>
      {text}
      {bonusAmount && <CoinTypography text={bonusAmount ? bonusAmount : 0} />}
    </BlackMassageButtonComponent>
  );
};

export default BlackMassageButton;
