import React from 'react';
import { BlackButtonComponent } from '../styles/styles';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type BlackButtonProps = {
  text: string;
  Icon?: React.ReactNode;
  textTransform: string;
  onClick?: () => void;
};

const BlackButton: React.FC<BlackButtonProps> = ({ text, textTransform, Icon, onClick }) => {
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const deviceId = params.get('deviceId');

  return (
    <BlackButtonComponent
      onClick={() => {
        onClick && onClick();
        navigate(`/?deviceId=${deviceId}`);
      }}
      sx={{ textTransform: textTransform }}
    >
      <Box sx={{ display: 'flex', gap: '11px', alignItems: 'center' }}>
        {Icon}
        {text}
      </Box>
    </BlackButtonComponent>
  );
};

export default BlackButton;
