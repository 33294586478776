import React, { useEffect, useState } from 'react';
import CoinContainer from '../container/coin-container';
import { Box, Stack } from '@mui/material';
import avatar from '../../assets/images/avatar.svg';
import { HeaderContainer } from '../styles/styles';
import LogoTypography from '../typography/logo-typography';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { User } from '../../../types/user.type';

const AuthorizedHeader = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const token = localStorage.getItem('token')?.replace(/"/g, '');
  const params = new URLSearchParams(location.search);
  const deviceId = params.get('deviceId');

  useEffect(() => {
    axios
      .get(`/api/users/${localStorage.getItem('user_id')}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUser(response.data);
      });
  }, []);

  return (
    <HeaderContainer>
      <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
        <CoinContainer text={user?.bonus_balance ? Math.round(user?.bonus_balance / 100) : 0} />
      </Box>
      <Stack alignItems={'center'}>
        <LogoTypography />
      </Stack>
      <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
        <img src={avatar} alt={''} onClick={() => navigate(`/account?deviceId=${deviceId}`)} />
      </Box>
    </HeaderContainer>
  );
};

export default AuthorizedHeader;
