import React from 'react';
import { Stack, Typography } from '@mui/material';
import theme from '../../../theme';
import coin from '../../assets/images/coin.svg';

type CoinContainerProps = {
  text: number | undefined;
};

const CoinContainer: React.FC<CoinContainerProps> = ({ text }) => {
  if (!text) {
    text = 0;
  }

  return (
    <Stack gap={'5px'} flexDirection={'row'}>
      <img src={coin} alt={'coin'} />
      <Typography fontFamily={'Bellota Text'} fontWeight={400} fontSize={'21px'} color={theme.custom.coin.color}>
        {text}
      </Typography>
    </Stack>
  );
};

export default CoinContainer;
