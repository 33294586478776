import React from 'react';
import coin from '../../assets/images/coin.svg';
import { CoinTypographyComponent } from '../styles/styles';

type CoinTypographyProps = {
  text: number;
};

const CoinTypography: React.FC<CoinTypographyProps> = ({ text }) => {
  return (
    <CoinTypographyComponent>
      +{text}
      <img src={coin} alt={''} />
    </CoinTypographyComponent>
  );
};

export default CoinTypography;
