import React from 'react';

const ArrowBackComponent = () => {
  return (
    <svg width='9' height='12' viewBox='0 0 9 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1 9.5L8 2V16.5L1 9.5Z' fill='#E8E8E8' stroke='#E8E8E8' />
    </svg>
  );
};

export default ArrowBackComponent;
