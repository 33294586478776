import React from 'react';
import { PaymentSecondaryTypographyComponent } from '../styles/styles';

type PaymentSecondaryTypographyProps = {
  text: string;
};

const PaymentSecondaryTypography: React.FC<PaymentSecondaryTypographyProps> = ({ text }) => {
  return <PaymentSecondaryTypographyComponent>{text}</PaymentSecondaryTypographyComponent>;
};

export default PaymentSecondaryTypography;
