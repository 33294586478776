import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type ProtectedRouteProps = {
  children: ReactNode;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const userString = localStorage.getItem('token');
  const params = new URLSearchParams(location.search);
  const deviceId = params.get('deviceId');
  const excludedPages = ['/scan-qr', '/payment-accepted', '/payment-denied', '/login', '/busy', '/contract-offer'];
  const checkUserToken = () => {
    setIsLoggedIn(Boolean(userString));

    if (excludedPages.includes(window.location.pathname)) {
      return;
    }

    if (!deviceId) {
      navigate('/scan-qr');

      return;
    }

    if (!userString) {
      navigate(`/login?deviceId=${deviceId}`);

      return;
    }
  };

  useEffect(() => {
    checkUserToken();
  }, [userString]);

  return <>{isLoggedIn ? props.children : null}</>;
};

export default ProtectedRoute;
