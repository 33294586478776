import React from 'react';
import { Stack } from '@mui/material';
import { InfoContainer, InfoTypographyComponent } from '../shared/ui-kit/styles/styles';

const ContractOffer = () => {
  return (
    <InfoContainer>
      <Stack>
        <InfoTypographyComponent>
          Индивидуальный предприниматель «Олейников Олег Евгеньевич», именуемый в дальнейшем «Исполнитель», действующий
          от своего имени на основании свидетельства о государственной регистрации в качества индивидуального
          предпринимателя 86 №001383151 от 22.02.2006, с одной стороны, и физическое лицо, принявшее условия настоящего
          договора в целях получения сеанса массажа, именуемое в дальнейшем «Заказчик», с другой стороны, совместно
          именуемые «Стороны», заключили настоящий Договор проката массажного оборудования (далее по тексту –
          «Договор»), являющийся публичным договором-офертой, о нижеследующем: публикует настоящий договор-оферту (далее
          – «Договор»), являющийся публичной офертой.
        </InfoTypographyComponent>
        <br />
        <InfoTypographyComponent>1. Предмет договора</InfoTypographyComponent>
      </Stack>
      <InfoTypographyComponent>
        1.1. В соответствии с условиями Договора Исполнитель обязуется предоставить Заказчику за оплату сеанса массажа
        во временное владение и пользование (далее – прокат) имеющиеся у Исполнителя на момент обращения массажное
        Оборудование, на условиях настоящего Договора.
      </InfoTypographyComponent>
      <Stack>
        <InfoTypographyComponent>
          1.2. Настоящий Договор опубликован на сайте Исполнителя: Свидетельством полного и безоговорочного акцепта
          (принятия) условий Договора является осуществление Заказчиком оплаты сеанса массажа на массажном Оборудовании.
        </InfoTypographyComponent>
        <InfoTypographyComponent>
          1.3 Настоящая Оферта считается основополагающим документом в официальных взаимоотношениях между Исполнителем и
          Заказчиком; принятие ее Заказчиком означает, что все условия поняты и приняты в полном объеме.
        </InfoTypographyComponent>

        <InfoTypographyComponent>
          1.4 Совершая действия по акцепту настоящей Оферты, Заказчик подтверждает свою правоспособность и
          дееспособность, а также законное право пользователя вступать в договорные отношения с Исполнителем.
        </InfoTypographyComponent>
      </Stack>
      <Stack>
        <InfoTypographyComponent>2. Права и обязанности сторон</InfoTypographyComponent>
        <InfoTypographyComponent>2.1. Исполнитель обязуется:</InfoTypographyComponent>
        <InfoTypographyComponent>
          2.1.1. Предоставить массажное Оборудование Заказчику в порядке и на условиях Договора;
        </InfoTypographyComponent>
        <InfoTypographyComponent>
          2.1.2. Осуществить возврат денежных средств Заказчику, в случае если Заказчик не смог использовать массажное
          Оборудование, по причинам не зависящим от него.
        </InfoTypographyComponent>
        <InfoTypographyComponent>2.2. Заказчик обязуется:</InfoTypographyComponent>
        <InfoTypographyComponent>
          2.2.1. Внести оплату за сеанс массажа на массажном Оборудовании;
        </InfoTypographyComponent>
        <InfoTypographyComponent>
          2.2.2. Не вскрывать, не модифицировать оборудование никаким образом, не производить какое-либо техническое
          обслуживание и ремонт;
        </InfoTypographyComponent>
        <InfoTypographyComponent>
          2.2.3. Возвратить арендованное массажное Оборудование Исполнителю по окончании сеанса массажа в том состоянии,
          в котором он его получил до начала сеанса массажа, с учетом естественного износа, в чистом (очищенном) виде, в
          противном случае Исполнитель производит чистку и ремонт Оборудования за счет Заказчика;
        </InfoTypographyComponent>
        <InfoTypographyComponent>
          2.2.4. Использовать массажное Оборудование согласно условиям Договора и в соответствии с назначением
          Оборудования;
        </InfoTypographyComponent>
        <InfoTypographyComponent>
          2.2.5. Если Заказчик во время сеанса массажа сломал массажное Оборудование, и оборудование не подлежит
          восстановлению, то Заказчик обязуется возместить Исполнителю все затраты, связанные с приобретением такого же,
          либо аналогичного оборудования (нового) по ценам, действующим на момент установления факта утери (в т.ч.
          хищения) массажного оборудования;
        </InfoTypographyComponent>
        <InfoTypographyComponent>
          2.2.6. Соблюдать надлежащий режим эксплуатации массажного Оборудования;
        </InfoTypographyComponent>
        <InfoTypographyComponent>
          2.2.8. Вносить арендную плату в размерах, порядке и сроки, установленные Договором;
        </InfoTypographyComponent>
        <InfoTypographyComponent>2.3. Исполнитель имеет право:</InfoTypographyComponent>
        <InfoTypographyComponent>
          2.3.1. Получить материальную компенсацию от Заказчика, в случае порчи Заказчиком Оборудования во время его
          использования;
        </InfoTypographyComponent>
        <InfoTypographyComponent>2.4. Заказчик имеет право:</InfoTypographyComponent>
        <InfoTypographyComponent>
          2.4.1. Получить возврат денежных средств, в случае если он не смог использовать Оборудование по причинам, не
          зависящим от него;
        </InfoTypographyComponent>
        <InfoTypographyComponent>
          2.4.2. Обратиться на горячую линию Исполнителя, в случае некорректной работы Оборудования (или за технической
          поддержкой, консультацией, иным причинам).
        </InfoTypographyComponent>
        <br />
        <Stack>
          <InfoTypographyComponent>
            3. Плата за сеанс массажа на массажном Оборудовании и Порядок расчетов
          </InfoTypographyComponent>
          <InfoTypographyComponent>
            3.1. Заказчик обязан вносить плату за сеанс массажа на массажном оборудовании, непосредственно перед его
            началом, в порядке, установленном Договором.
          </InfoTypographyComponent>
          <InfoTypographyComponent>
            3.2. Внесение платы за сеанс массажа на массажном Оборудовании осуществляется путем списания денежных
            средств с банковской карты Заказчика.
          </InfoTypographyComponent>
        </Stack>
        <Stack>
          <br />
          <InfoTypographyComponent>4. Ответственность Сторон</InfoTypographyComponent>
          <InfoTypographyComponent>
            4.1. Стороны несут ответственность за неисполнение или ненадлежащее исполнение своих обязательств по
            Договору в соответствии с действующим законодательством.
          </InfoTypographyComponent>
        </Stack>
        <br />
        <Stack>
          <InfoTypographyComponent>5. Персональные данные</InfoTypographyComponent>
          <InfoTypographyComponent>
            5.1. Заказчик дает Исполнителю добровольное информированное согласие на обработку его персональных данных,
            включая фамилию и имя, номер телефона, адрес электронной почты в целях исполнения Договора. Заключая
            настоящий Договор, Заказчик подтверждает, что он ознакомился и согласен с Политикой Исполнителя в отношении
            обработки персональных данных.
          </InfoTypographyComponent>
          <InfoTypographyComponent>
            5.2. Обработка персональных данных Заказчика включает их хранение, обработку, деперсонализацию и
            уничтожение.
          </InfoTypographyComponent>
          <InfoTypographyComponent>
            5.3. Исполнитель использует персональные данные Заказчика в маркетинговых, рекламных и информационных целях,
            включая:
          </InfoTypographyComponent>
          <InfoTypographyComponent>– информирование о конкурсах и рекламных акциях;</InfoTypographyComponent>
          <InfoTypographyComponent>– рассылку новостей и бизнес-предложений;</InfoTypographyComponent>
          <InfoTypographyComponent>
            – персонализацию сайта на основе статистического анализа данных.
          </InfoTypographyComponent>
          <InfoTypographyComponent>
            5.4. Исполнитель имеет право рассылать электронные письма и SMS-сообщения, содержащие:
          </InfoTypographyComponent>
          <InfoTypographyComponent>– новости;</InfoTypographyComponent>
          <InfoTypographyComponent>– рекламные акции;</InfoTypographyComponent>
          <InfoTypographyComponent>– приглашения на вебинары;</InfoTypographyComponent>
          <InfoTypographyComponent>– обучение и консультации;</InfoTypographyComponent>
          <InfoTypographyComponent>– уведомление о событиях.</InfoTypographyComponent>
          <InfoTypographyComponent>
            5.5. Заказчик вправе отказаться от получения рассылки и (или) потребовать от Исполнителя уничтожения всех
            собранных им персональных данных Заказчика, уведомив об этом Исполнителя в разумный срок.
          </InfoTypographyComponent>
        </Stack>
        <br />
        <Stack>
          <InfoTypographyComponent>6. Возврат денежных средств Заказчику.</InfoTypographyComponent>
          <InfoTypographyComponent>
            6.1. Заказчик имеет право на возврат денежных средств, которыми он оплатил Аренду Оборудования Исполнителя в
            случае, если он не смог использовать массажное Оборудование, по причинам не зависящим от него.
          </InfoTypographyComponent>
          <InfoTypographyComponent>
            6.2. Для возврата денежных средств Заказчику предоставлено право обращения на горячую линию Исполнителя для
            оформления заявки на возврат денежных средств.
          </InfoTypographyComponent>
        </Stack>
        <br />
        <Stack>
          <InfoTypographyComponent>7. Разрешение споров из договора</InfoTypographyComponent>
          <InfoTypographyComponent>
            7.1. Претензионный порядок досудебного урегулирования споров из Договора является для Сторон обязательным.
          </InfoTypographyComponent>
          <InfoTypographyComponent>
            7.2. Споры из Договора разрешаются в судебном порядке по месту нахождения Исполнителя, в соответствии с
            действующим законодательством.
          </InfoTypographyComponent>
        </Stack>
        <br />
        <Stack>
          <InfoTypographyComponent>8. Форс-мажор</InfoTypographyComponent>
          <InfoTypographyComponent>
            8.1. Стороны освобождаются от ответственности за полное или частичное неисполнение обязательств по Договору
            в случае, если неисполнение обязательств явилось следствием действий непреодолимой силы, а именно: пожара,
            наводнения, землетрясения, забастовки, войны, действий органов государственной власти или других независящих
            от Сторон обстоятельств.
          </InfoTypographyComponent>
        </Stack>
        <br />
        <Stack>
          <InfoTypographyComponent>
            9.Порядок использования Арендованного Оборудования Заказчиком
          </InfoTypographyComponent>
          <InfoTypographyComponent>9.1. Заказчик сканирует Оборудование (массажное кресло);</InfoTypographyComponent>
          <InfoTypographyComponent>
            9.1.1. Заказчик Выбирает длительность массажа за указанную стоимость Аренды Оборудования;
          </InfoTypographyComponent>
          <InfoTypographyComponent>9.1.2. Заказчик производит оплату Аренды Оборудования;</InfoTypographyComponent>
          <InfoTypographyComponent>
            9.1.3. Системой контроля проверяется успешность оплаты Аренды Оборудования и производится включение
            Оборудования, которое отсканировал Заказчик.
          </InfoTypographyComponent>
          <InfoTypographyComponent>
            9.2. Если Оборудование по каким-либо причинам не смогло включиться, Заказчик сканирует рядом стоящее
            Оборудование и получает массаж на Арендованном Оборудовании по совершенной оплате.
          </InfoTypographyComponent>
          <InfoTypographyComponent>
            9.2.1. При возникновении ситуаций, прямо не зависящих от действий или бездействия Исполнителя (например,
            отключили подачу электричества в процессе массажа на Арендованном Оборудовании), Заказчик имеет право
            совершить звонок на горячую линию Исполнителя и создать заявку на возврат денежных средств, уплаченных
            Исполнителю за Аренду Оборудования.
          </InfoTypographyComponent>
        </Stack>
        <br />
        <Stack>
          <InfoTypographyComponent>10. Реквизиты</InfoTypographyComponent>
          <InfoTypographyComponent>ИП ОЛЕЙНИКОВ ОЛЕГ ЕВГЕНЬЕВИЧ</InfoTypographyComponent>
          <InfoTypographyComponent>ИНН 860223416098</InfoTypographyComponent>
          <InfoTypographyComponent>БИК 046577964</InfoTypographyComponent>
          <InfoTypographyComponent>Номер счета 40802810338380003053</InfoTypographyComponent>
          <InfoTypographyComponent>Банк: ФИЛИАЛ "ЕКАТЕРИНБУРГСКИЙ" АО "АЛЬФА-БАНК"</InfoTypographyComponent>
          <InfoTypographyComponent>Корр.счет 30101810100000000964</InfoTypographyComponent>
          <InfoTypographyComponent>
            Юридический адрес компании: улица Энергетиков, д. 3/1, кв./оф. 3, Ханты-Мансийский автономный окр., г.
            Сургут
          </InfoTypographyComponent>
        </Stack>
      </Stack>
    </InfoContainer>
  );
};

export default ContractOffer;
