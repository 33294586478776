import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import MassagePage from './pages/massage.page';
import LoginPage from './pages/login.page';
import Layout from './shared/layout/layout';
import HowToChangePage from './pages/how-to-change.page';
import AccountPage from './pages/account.page';
import PaymentAcceptedPage from './pages/payment-accepted.page';
import PaymentDeniedPage from './pages/payment-denied.page';
import ContractOffer from './pages/contract-offer';
import LayoutDocs from './shared/layout/layout-docs';
import AboutCoins from './pages/about-coins';
import axios from 'axios';
import { RootStoreProvider } from './context/root-store-context';
import ProtectedRoute from './router/protected-router';
import ScanQrPage from './pages/scan-qr.page';
import IsBusyPage from './pages/is-busy.page';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <Layout>
        <ProtectedRoute>
          <MassagePage />
        </ProtectedRoute>
      </Layout>
    ),
  },
  {
    path: '/',
    element: (
      <Layout>
        <ProtectedRoute>
          <MassagePage />
        </ProtectedRoute>
      </Layout>
    ),
  },
  {
    path: '/login',
    element: (
      <Layout>
        <LoginPage />
      </Layout>
    ),
  },
  {
    path: '/scan-qr',
    element: <ScanQrPage />,
  },
  {
    path: '/busy',
    element: <IsBusyPage />,
  },
  {
    path: '/how-to-change',
    element: (
      <Layout>
        <ProtectedRoute>
          <HowToChangePage />
        </ProtectedRoute>
      </Layout>
    ),
  },
  {
    path: '/payment-accepted',
    element: (
      <Layout>
        <PaymentAcceptedPage />
      </Layout>
    ),
  },
  {
    path: '/payment-denied',
    element: (
      <Layout>
        <PaymentDeniedPage />
      </Layout>
    ),
  },
  {
    path: '/account',
    element: (
      <Layout>
        <ProtectedRoute>
          <AccountPage />
        </ProtectedRoute>
      </Layout>
    ),
  },
  {
    path: '/contract-offer',
    element: (
      <LayoutDocs text={'Договор оферты'}>
        <ProtectedRoute>
          <ContractOffer />
        </ProtectedRoute>
      </LayoutDocs>
    ),
  },
  {
    path: '/about-coins',
    element: (
      <LayoutDocs text={'Подробно о баллах'}>
        <ProtectedRoute>
          <AboutCoins />
        </ProtectedRoute>
      </LayoutDocs>
    ),
  },
]);

root.render(
  <RootStoreProvider>
    <App>
      <RouterProvider router={router} />
    </App>
  </RootStoreProvider>,
);
