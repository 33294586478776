import React, { useEffect, useState } from 'react';
import TitleTypography from '../shared/ui-kit/typography/title-typography';
import RenameNumberTypography from '../shared/ui-kit/typography/rename-number-typography';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { MassageContainer, PaymentMethodContainer } from '../shared/ui-kit/styles/styles';
import axios from 'axios';
import { Program } from '../types/programs.type';
import { SessionDuration } from '../types/sesstion-duration.type';
import { handleAxiosError } from '../shared/lib/handle-axios-error';
import { millisToMinutes } from '../shared/lib/convert-milliseconds-to-seconds';
import ProgramComponent from '../shared/ui-kit/components/program-component';
import DurationComponent from '../shared/ui-kit/components/duration-component';
import PaymentMethodTypography from '../shared/ui-kit/typography/payment-method-typography';
import { Box, Stack } from '@mui/material';
import BlackPaymentButton from '../shared/ui-kit/button/black-payment-button';
import ArrowBackComponent from '../shared/assets/icon-component/arrow-back-component';
import BlackButton from '../shared/ui-kit/button/black-button';
import { User } from '../types/user.type';

const MassagePage = () => {
  const navigate = useNavigate();
  const [programs, setPrograms] = useState<Program[]>([]);
  const [sessionDuration, setSessionDuration] = useState<SessionDuration[]>([]);
  const [isErrorText, setIsErrorText] = useState<boolean>(false);
  const [selectedProgramId, setSelectedProgramId] = useState<number | null>(null);
  const [massageDuration, setMassageDuration] = useState<number | null>(null);
  const [hasReadyToPayment, setHasReadyToPayment] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<string>('');
  const [user, setUser] = useState<User | null>(null);
  const [url, setUrl] = useState<string | null>(null);
  const token = localStorage.getItem('token')?.replace(/"/g, '');
  const params = new URLSearchParams(location.search);
  const deviceId = params.get('deviceId');

  useEffect(() => {
    if (!deviceId) {
      navigate('/scan-qr');

      return;
    }

    axios
      .get(`/api/device/${deviceId}`)
      .then((res) => {
        if (res.data.is_busy) {
          navigate({
            pathname: '/busy',
            search: createSearchParams({
              deviceId: deviceId,
            }).toString(),
          });
        }
      })
      .catch(() => {
        navigate('/scan-qr');
      });
  }, [deviceId]);

  const fetchUser = async () => {
    try {
      const response = await axios.get(`/api/users/${localStorage.getItem('user_id')}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setUser(response.data);
    } catch (error) {
      setIsErrorText(true);
    }
  };

  const isDeviceIdValid = (deviceId: any): deviceId is number => {
    return !isNaN(Number(deviceId));
  };

  const handlePayment = async () => {
    if (!isDeviceIdValid(deviceId)) {
      navigate('/scan-qr');

      return;
    }

    try {
      await axios.post(
        '/api/session',
        {
          device_id: Number(deviceId),
          program_id: selectedProgramId,
          duration: massageDuration,
          payment_type: paymentMethod,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (error) {
      handleAxiosError(error, deviceId);
    }
  };

  const checkBonuses = async () => {
    if (!user) {
      navigate(`/login?deviceId=${deviceId}`);

      return;
    }

    if (user?.bonus_balance < calculatePrice(massageDuration as number)) {
      navigate('/payment-denied');

      return;
    }

    await handlePayment();

    navigate('/payment-accepted');
  };

  const fetchPrograms = async () => {
    try {
      const response = await axios.get(`/api/device/${deviceId}/programs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });

      setIsErrorText(false);
      setPrograms(response.data);
    } catch (error) {
      setIsErrorText(true);
      handleAxiosError(error, deviceId);
    }
  };

  const fetchSessionDuration = async () => {
    try {
      const response = await axios.get('/api/catalog/session_duration', {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setSessionDuration(response.data);
    } catch (error) {
      setIsErrorText(true);
      handleAxiosError(error, deviceId);
    }
  };

  useEffect(() => {
    fetchUser();
    fetchPrograms();
    fetchSessionDuration();
  }, []);

  const calculatePrice = (durationMilliseconds: number) => {
    const session = sessionDuration.find((session) => session.duration_in_milliseconds === durationMilliseconds);

    if (session) {
      const program = programs.find((program) => program.id === selectedProgramId);

      if (program) {
        const pricePerMinute = program.price_per_minute / 100;

        return pricePerMinute * millisToMinutes(durationMilliseconds);
      }
    }

    return 100;
  };

  const calculateBonus = (durationMilliseconds: number) => {
    const program = programs.find((program) => program.id === selectedProgramId);

    if (program) {
      return (millisToMinutes(durationMilliseconds) * program.bonus_amount_per_minute) / 100;
    }

    return 20;
  };

  if (selectedProgramId && massageDuration && !hasReadyToPayment) {
    setHasReadyToPayment(true);
  }

  const handlePaymentCard = () => {
    if (!isDeviceIdValid(deviceId)) {
      navigate('/scan-qr');

      return;
    }

    axios
      .post(
        '/api/session',
        {
          device_id: Number(deviceId),
          program_id: selectedProgramId,
          duration: massageDuration,
          payment_type: 'MONEY',
        },
        { headers: { Authorization: `Bearer ${token}` } },
      )
      .then((res) => {
        setUrl(res.data.payment_url);
      })
      .catch((err) => {
        handleAxiosError(err, deviceId);
      });
  };

  if (url) {
    window.location.href = process.env.REACT_APP_ALFA_BANK_URL + url;
  }

  return (
    <MassageContainer>
      {hasReadyToPayment ? (
        <>
          {paymentMethod === 'BONUSES' ? (
            <PaymentMethodContainer>
              <PaymentMethodTypography
                text={`Вы хотите списать ${calculatePrice(massageDuration as number)} баллов и оплатить массаж`}
              />
              <Box sx={{ display: 'flex', gap: '21px' }}>
                <BlackPaymentButton onClick={checkBonuses} text={'Списать'} />
                <BlackPaymentButton onClick={() => setPaymentMethod('MONEY')} text={'Назад'} />
              </Box>
            </PaymentMethodContainer>
          ) : (
            <>
              <PaymentMethodContainer>
                <PaymentMethodTypography text={'Выберите способ оплаты'} />
                <Box sx={{ display: 'flex', gap: '21px' }}>
                  <BlackPaymentButton onClick={() => setPaymentMethod('BONUSES')} text={'Баллами'} />
                  <BlackPaymentButton
                    onClick={() => {
                      setPaymentMethod('MONEY');
                      handlePaymentCard();
                    }}
                    text={'Картой'}
                  />
                </Box>
              </PaymentMethodContainer>
              <BlackButton
                Icon={<ArrowBackComponent />}
                onClick={() => {
                  setMassageDuration(null);
                  setHasReadyToPayment(false);
                }}
                text={'назад'}
                textTransform={'uppercase'}
              />
            </>
          )}
        </>
      ) : (
        <>
          {!selectedProgramId ? (
            <TitleTypography titleText={'Выберите тип массажа'} />
          ) : (
            <TitleTypography titleText={'Выберите время массажа'} />
          )}
          {!selectedProgramId ? (
            <ProgramComponent programs={programs} setSelectedProgramId={setSelectedProgramId} />
          ) : (
            <DurationComponent
              sessionDuration={sessionDuration}
              setSelectedProgramId={setSelectedProgramId}
              setMassageDuration={setMassageDuration}
              calculatePrice={calculatePrice}
              calculateBonus={calculateBonus}
            />
          )}
          {isErrorText && <TitleTypography titleText={'Произошла ошибка, попробуйте обновить страницу'} />}
          <Stack sx={{ gap: '24px' }}>
            <RenameNumberTypography
              text={'Какой массаж выбрать?'}
              onClick={() => navigate(`/how-to-change?deviceId=${deviceId}`)}
            />
            <RenameNumberTypography text={'Договор оферты'} onClick={() => navigate('/contract-offer')} />
          </Stack>
        </>
      )}
    </MassageContainer>
  );
};

export default MassagePage;
