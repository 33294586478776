import React from 'react';
import BlackMassageButton from '../button/black-massage-button';
import { CircularProgress } from '@mui/material';
import { Program } from '../../../types/programs.type';

type ProgramComponentProps = {
  programs: Program[];
  setSelectedProgramId: (id: number) => void;
};

const ProgramComponent: React.FC<ProgramComponentProps> = ({ programs, setSelectedProgramId }) => {
  return (
    <>
      {programs.length ? (
        <>
          {programs.map((program) => (
            <BlackMassageButton onClick={() => setSelectedProgramId(program.id)} key={program.id} text={program.name} />
          ))}
        </>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default ProgramComponent;
