import React from 'react';
import { CircleFigureComponent } from '../styles/styles';
import CheckMarkComponent from '../../assets/icon-component/check-mark-component';
import PaymentAcceptTypography from '../typography/payment-accept-typography';

const CircleFigure = () => {
  return (
    <CircleFigureComponent>
      <CheckMarkComponent />
      <PaymentAcceptTypography text={'Оплата прошла УСПЕШНО'} />
    </CircleFigureComponent>
  );
};

export default CircleFigure;
