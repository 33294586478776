import React from 'react';
import { InActiveTypographyComponent } from '../styles/styles';

type InactiveTypographyProps = {
  text: string;
  onClick?: () => void;
};

const InactiveTypography: React.FC<InactiveTypographyProps> = ({ text, onClick }) => {
  return <InActiveTypographyComponent onClick={onClick}>{text}</InActiveTypographyComponent>;
};

export default InactiveTypography;
