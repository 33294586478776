import React from 'react';
import BlackMassageButton from '../button/black-massage-button';
import { millisToMinutes } from '../../lib/convert-milliseconds-to-seconds';
import BlackButton from '../button/black-button';
import ArrowBackComponent from '../../assets/icon-component/arrow-back-component';
import { SessionDuration } from '../../../types/sesstion-duration.type';

type DurationComponentProps = {
  sessionDuration: SessionDuration[];
  setSelectedProgramId: (id: number | null) => void;
  setMassageDuration: (duration: number | null) => void;
  calculatePrice: (durationMilliseconds: number) => number;
  calculateBonus: (durationMilliseconds: number) => number;
};

const DurationComponent: React.FC<DurationComponentProps> = ({
  sessionDuration,
  setSelectedProgramId,
  calculateBonus,
  calculatePrice,
  setMassageDuration,
}) => {
  return (
    <>
      {sessionDuration.map((session) => (
        <BlackMassageButton
          onClick={() => setMassageDuration(session.duration_in_milliseconds)}
          key={session.duration_in_milliseconds}
          text={`${millisToMinutes(session.duration_in_milliseconds)} минут за ${calculatePrice(session.duration_in_milliseconds)} рублей`}
          bonusAmount={calculateBonus(session.duration_in_milliseconds)}
        />
      ))}
      <BlackButton
        onClick={() => setSelectedProgramId(null)}
        Icon={<ArrowBackComponent />}
        text={'назад'}
        textTransform={'uppercase'}
      />
    </>
  );
};

export default DurationComponent;
