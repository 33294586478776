import React from 'react';
import { Stack } from '@mui/material';
import { ArrowBackIosNewRounded } from '@mui/icons-material';
import TitleTypography from '../ui-kit/typography/title-typography';
import { useNavigate } from 'react-router-dom';
import BackgroundCircle from '../ui-kit/background/background-circle';
import theme from '../../theme';
import { LayoutDocsContainer } from '../ui-kit/styles/styles';

type LayoutDocsProps = {
  children: React.ReactNode;
  text: string;
};

const LayoutDocs: React.FC<LayoutDocsProps> = ({ children, text }) => {
  const navigate = useNavigate();

  return (
    <LayoutDocsContainer>
      <BackgroundCircle isTopPosition={false} />
      <Stack flexDirection={'row'} alignItems={'center'}>
        <ArrowBackIosNewRounded onClick={() => navigate(-1)} sx={{ color: theme.custom.text.white }} />
        <TitleTypography titleText={text} />
      </Stack>
      {children}
    </LayoutDocsContainer>
  );
};

export default LayoutDocs;
