const theme = {
  custom: {
    main: {
      color: '#FFD700',
      background: '#0B080A',
      yellowBlur: '#695903',
    },

    coin: {
      color: '#EFB832',
    },
    text: {
      white: '#E8E8E8',
      disable: '#D9D9D9',
      inactive: '#8F8F8F',
      gray: '#B6B6B6',
      yellow: '#FFD700',
    },
    button: {
      active: 'linear-gradient(#F8EDB0, #FFD700)',
      inactive: 'linear-gradient(#A8A17C, #B19601)',
      line: 'linear-gradient(#A8A17C03 1%, #B1960103 1%)',
      disable: 'linear-gradient(#A8A17C03 1%, #B1960103 1%)',
    },
  },
};

export default theme;
