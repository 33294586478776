import React from 'react';
import { Typography } from '@mui/material';
import LogoTypography from '../shared/ui-kit/typography/logo-typography';

const IsBusyPage = () => {
  return (
    <div style={{ padding: '24px' }}>
      <LogoTypography />
      <Typography sx={{ color: 'white', fontSize: '34px', textAlign: 'center', marginTop: '36px' }}>
        Устройство занято, попробуйте отсканировать код позже
      </Typography>
    </div>
  );
};

export default IsBusyPage;
