import React from 'react';
import { YellowButtonComponent } from '../styles/styles';

type YellowButtonProps = {
  text: string;
  onClick: () => void;
  sx?: any;
  isDisabled?: boolean;
};

const YellowButton: React.FC<YellowButtonProps> = ({ text, onClick, sx, isDisabled }) => {
  return (
    <YellowButtonComponent disabled={isDisabled} sx={{ ...sx }} onClick={onClick} variant={'contained'}>
      {text}
    </YellowButtonComponent>
  );
};

export default YellowButton;
