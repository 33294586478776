import React, { createContext, ReactElement } from 'react';
import { IUserStore } from '../interfaces/IUserStore';
import { UserStore } from '../store/user.store';

class RootStore {
  constructor(public readonly userStore: IUserStore) {}
}

export const RootStoreContext = createContext<RootStore>({} as RootStore);

const userStore = new UserStore();

const rootStore = new RootStore(userStore);

export const RootStoreProvider: React.FC<React.PropsWithChildren<Record<string, ReactElement>>> = ({ children }) => {
  return <RootStoreContext.Provider value={rootStore}>{children}</RootStoreContext.Provider>;
};
