import React from 'react';
import { PaymentMethodTypographyComponent } from '../styles/styles';

type PaymentMethodTypographyProps = {
  text: string;
};

const PaymentMethodTypography: React.FC<PaymentMethodTypographyProps> = ({ text }) => {
  return <PaymentMethodTypographyComponent>{text}</PaymentMethodTypographyComponent>;
};

export default PaymentMethodTypography;
